<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed inset-0 overflow-hidden"
      @close="deleteOpen || greetingRemove ? null : $emit('close')"
      :open="open && !(deleteOpen || greetingRemove)"
    >
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="
              absolute
              inset-0
              bg-gray-500 bg-opacity-75
              transition-opacity
            "
          />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  py-6
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="text-lg font-medium text-gray-900">
                      {{ this.isEdit ? "Edit" : "Add New" }} Device
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        class="
                          bg-white
                          rounded-md
                          text-gray-400
                          hover:text-gray-500
                          focus:outline-none
                        "
                        @click="$emit('close')"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-2 relative flex-1">
                  <div class="">
                    <form
                      class="h-full divide-y divide-gray-200 flex flex-col"
                      @submit="save"
                    >
                      <div class="flex-1 h-0 overflow-y-auto">
                        <div class="flex-1 flex flex-col justify-between">
                          <div class="px-4 divide-y divide-gray-200 sm:px-6">
                            <div class="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  Username *
                                </label>
                                <div class="mt-1">
                                  <input
                                    :disabled="isEdit ? true : false"
                                    v-model="username"
                                    placeholder="username"
                                    type="text"
                                    class="
                                      block
                                      w-full
                                      shadow-sm
                                      sm:text-sm
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      border-gray-300
                                      rounded-md
                                    "
                                  />
                                </div>
                              </div>
                              <div v-if="isEdit">
                                <label
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  Password *
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="password"
                                    placeholder="password"
                                    type="text"
                                    class="
                                      block
                                      w-full
                                      shadow-sm
                                      sm:text-sm
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      border-gray-300
                                      rounded-md
                                    "
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  Caller ID *
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="callerId"
                                    placeholder="callerId"
                                    type="text"
                                    class="
                                      block
                                      w-full
                                      shadow-sm
                                      sm:text-sm
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      border-gray-300
                                      rounded-md
                                    "
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  Voicemail Pin *
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="voicemailPin"
                                    placeholder="4 digits voicemail Pin"
                                    type="text"
                                    class="
                                      block
                                      w-full
                                      shadow-sm
                                      sm:text-sm
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      border-gray-300
                                      rounded-md
                                    "
                                  />
                                </div>
                              </div>
                              <div>
                                <h3 class="text-sm font-medium text-gray-900">
                                  WEBRtc
                                </h3>
                                <div class="mt-2">
                                  <div class="flex space-x-2">
                                    <Switch
                                      v-model="webRTC"
                                      :class="[
                                        webRTC
                                          ? 'bg-indigo-600'
                                          : 'bg-gray-200',
                                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                                      ]"
                                    >
                                      <span class="sr-only">WEBRtc</span>
                                      <span
                                        aria-hidden="true"
                                        :class="[
                                          webRTC
                                            ? 'translate-x-5'
                                            : 'translate-x-0',
                                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                                        ]"
                                      />
                                    </Switch>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <label
                                  for="description"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  Notes
                                </label>
                                <div class="mt-1">
                                  <textarea
                                    v-model="note"
                                    rows="2"
                                    class="
                                      block
                                      w-full
                                      shadow-sm
                                      sm:text-sm
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      border border-gray-300
                                      rounded-md
                                    "
                                  />
                                </div>
                              </div>

                              <div>
                                <label
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                >
                                  Voicemail greeting
                                </label>
                                <div
                                  class="mt-2 mb-3 flex"
                                  v-if="voicemailRecord"
                                >
                                  <audio controls class="h-10 w-full">
                                    <source
                                      :src="voicemailRecord"
                                      type="audio/wav"
                                    />
                                  </audio>
                                  <button
                                    @click="greetingRemove = true"
                                    type="button"
                                    class="
                                      ml-1
                                      inline-flex
                                      items-center
                                      justify-center
                                      px-2
                                      py-1
                                      border
                                      text-xs
                                      border-transparent
                                      font-medium
                                      rounded-md
                                      text-red-700
                                      bg-red-100
                                      hover:bg-red-200
                                      focus:outline-none
                                    "
                                  >
                                    Remove
                                  </button>
                                </div>
                                <div
                                  class="
                                    mt-1
                                    flex
                                    justify-center
                                    px-6
                                    pt-5
                                    pb-6
                                    border-2 border-gray-300 border-dashed
                                    rounded-md
                                  "
                                  @dragstart.prevent
                                  @dragover.prevent
                                  @dragenter.prevent
                                  @drop="onDrop"
                                >
                                  <div class="space-y-1 text-center">
                                    <svg
                                      class="mx-auto h-12 w-12 text-gray-400"
                                      stroke="currentColor"
                                      fill="none"
                                      viewBox="0 0 48 48"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    <div class="flex text-sm text-gray-600">
                                      <label
                                        for="file-upload"
                                        class="
                                          relative
                                          cursor-pointer
                                          bg-white
                                          rounded-md
                                          font-medium
                                          text-indigo-600
                                          hover:text-indigo-500
                                          focus-within:outline-none
                                          focus-within:ring-2
                                          focus-within:ring-offset-2
                                          focus-within:ring-indigo-500
                                        "
                                      >
                                        <span>Upload a file</span>
                                        <input
                                          id="file-upload"
                                          name="file-upload"
                                          type="file"
                                          class="sr-only"
                                          accept="audio/*"
                                          @change="greetingFileChanged"
                                        />
                                      </label>
                                      <p class="pl-1">
                                        {{
                                          uploadedFile
                                            ? uploadedFile.name
                                            : "or drag and drop"
                                        }}
                                      </p>
                                    </div>
                                    <p class="text-xs text-gray-500">
                                      MP3, WAV
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="errors.length !== 0" class="bg-red-50 p-4">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <XCircleIcon
                              class="h-5 w-5 text-red-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div class="ml-3">
                            <h3 class="text-sm font-medium text-red-800">
                              There were errors with your submission
                            </h3>
                            <div class="mt-2 text-sm text-red-700">
                              <ul class="list-disc pl-5 space-y-1">
                                <li v-for="error in errors" :key="error">
                                  {{ error }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="success" class="rounded-md bg-green-50 p-4">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <CheckCircleIcon
                              class="h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div class="ml-3">
                            <h3 class="text-sm font-medium text-green-800">
                              {{ success }}
                            </h3>
                          </div>
                        </div>
                      </div>

                      <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                        <button
                          @click="deleteOpen = true"
                          v-if="isEdit"
                          type="button"
                          class="
                            inline-flex
                            items-center
                            justify-center
                            px-4
                            py-2
                            border border-transparent
                            font-medium
                            rounded-md
                            text-red-700
                            bg-red-100
                            hover:bg-red-200
                            focus:outline-none
                          "
                        >
                          Delete
                        </button>
                        <button
                          :disabled="loading"
                          type="submit"
                          class="
                            ml-4
                            inline-flex
                            justify-center
                            py-2
                            px-4
                            border border-transparent
                            shadow-sm
                            text-sm
                            font-medium
                            rounded-md
                            text-white
                            bg-indigo-600
                            hover:bg-indigo-700
                            focus:outline-none
                          "
                        >
                          {{ loading ? "Wait.." : "Save" }}
                        </button>
                      </div>
                    </form>
                  </div>

                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <GreetingRemove
    :id="voicemailRecordId ?? 0"
    :open="greetingRemove"
    @close="greetingRemoveClose"
  />

  <EndpointDelete :id="id ?? 0" :open="deleteOpen" @close="addClosed" />
</template>

<script>
import EndpointDelete from "@/modules/end_points/views/delete.vue";
import GreetingRemove from "@/modules/end_points/views/remove_greeting.vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  Switch,
} from "@headlessui/vue";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/vue/solid";
import { XIcon } from "@heroicons/vue/outline";
import endpointsApi from "@/network/refactored/endpoints.js";
import { mapActions } from "vuex";
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    Switch,
    XCircleIcon,
    CheckCircleIcon,
    EndpointDelete,
    GreetingRemove,
  },

  emits: ["close"],

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      id: 0,
      isEdit: false,
      loading: false,
      errors: [],
      success: null,

      username: "",
      password: "",
      callerId: "",
      voicemailPin: "0000",
      note: "",
      voicemailRecord: null,
      voicemailRecordId: 0,
      webRTC: true,

      deleteOpen: false,
      greetingRemove: false,

      uploadedFile: null,
    };
  },

  methods: {
    greetingFileChanged(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.uploadedFile = files[0];
    },

    greetingRemoveClose(removed) {
      this.greetingRemove = false;
      if (removed) {
        console.log("WARD 2");
        this.voicemailRecord = null;
      }
    },

    addClosed(removed) {
      this.deleteOpen = false;
      if (removed) {
        this.$emit("close");
      }
    },

    async reset(data) {
      this.loading = false;
      this.errors = [];
      this.success = null;

      this.id = data?.id ?? 0;
      this.isEdit = data ? true : false;
      this.username = data?.username ?? "";
      this.note = data?.note ?? "";
      this.password = data?.password ?? "";
      this.callerId = data?.callerid ?? "";
      this.webRTC = data?.webrtc ? true : false;
      this.voicemailPin = data?.voicemail_pin ?? "0000";

      this.voicemailRecord = null;
      this.voicemailRecordId = null;
      this.uploadedFile = null;

      if (this.isEdit) {
        const res = await endpointsApi.getEndpoint(this.id);
        this.voicemailRecord = res.data?.voicemail_greeting
          ? res.data?.voicemail_greeting.message_recording
          : null;
        this.voicemailRecordId = res.data?.voicemail_greeting
          ? res.data?.voicemail_greeting.id
          : 0;
      }
    },

    async save(e) {
      e.preventDefault();
      if (!this.validate()) return;

      this.loading = true;
      try {
        if (this.isEdit) {
          const { data } = await endpointsApi.update(
            this.id,
            this.username,
            this.password,
            this.callerId,
            this.voicemailPin,
            this.webRTC,
            this.note
          );
          if (this.uploadedFile) {
            let formData = new FormData();
            formData.append("greeting_file", this.uploadedFile);
            formData.append("mailbox", data.username);
            await endpointsApi.uploadGreeting(formData);
          }
          this.success = "Endpoint updated successfully";
          this.voicemailRecord = data?.voicemail_greeting
            ? data?.voicemail_greeting.message_recording
            : null;
          this.updateEndpoint({ endpoint: data });
        } else {
          const { data } = await endpointsApi.createEndPoint(
            this.username,
            this.password,
            this.callerId,
            this.voicemailPin,
            this.webRTC,
            this.note
          );

          if (this.uploadedFile) {
            let formData = new FormData();
            formData.append("greeting_file", this.uploadedFile);
            formData.append("mailbox", data.username);
            await endpointsApi.uploadGreeting(formData);
          }

          this.addEndpoint({ endpoint: data });
          this.reset();
          this.uploadedFile = null;
          this.success = "Endpoint added successfully";
        }
      } catch (error) {
        if (error.response) {
          this.errors.push(error.response.data["message"]);
        } else {
          this.errors.push("Error while trying to submit data");
        }
      } finally {
        this.loading = false;
      }
    },

    validate() {
      this.errors = [];
      this.success = null;

      let isValid = true;
      if (this.username === "" || this.username.length < 6) {
        isValid = false;
        this.errors.push("username should be at least 6 charachters");
      }

      // if (this.password === "" || this.password.length < 8) {
      //   isValid = false;
      //   this.errors.push("password should be at least 8 charachters");
      // }

      // if (this.callerId === "") {
      //   isValid = false;
      //   this.errors.push("Enter a caller id");
      // }

      let valid_formats = new Set(["audio/wav", "audio/mpeg"]);

      if (!valid_formats.has(this.uploadedFile.type)) {
        isValid = false;
        this.errors.push("Only WAV or MP3 files are allowed");
      }

      if (
        this.voicemailPin === "" ||
        isNaN(this.voicemailPin) ||
        this.voicemailPin.length !== 4
      ) {
        isValid = false;
        this.errors.push("Enter a 4 digits voicemail pin");
      }

      return isValid;
    },
    onDrop(ev) {
      this.errors = [];
      ev.preventDefault();
      if (!ev.dataTransfer.items) return;

      if (ev.dataTransfer.items[0].kind !== "file") return;

      let file = ev.dataTransfer.items[0].getAsFile();
      this.uploadedFile = file;
      ev.stopPropagation();
      return false;
    },
    ...mapActions("endpoints", ["addEndpoint", "updateEndpoint"]),
  },
};
</script>
