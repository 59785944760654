<template>
  <tr>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ endpoint.username }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ endpoint.callerid }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ endpoint.notes }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <span
        class="
          px-2
          inline-flex
          text-xs
          leading-5
          font-semibold
          rounded-full
          bg-green-100
          text-green-800
        "
      >
        {{ endpoint.webrtc ? "yes" : "no" }}
      </span>
    </td>
    <!-- <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      <audio
        v-if="endpoint.voicemail_greeting"
        controls
        class="h-10 w-full"
        download="The_title_of_te_programme.wav"
      >
        <source
          :src="endpoint.voicemail_greeting.message_recording"
          type="audio/wav"
        />
      </audio>
    </td> -->
    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
      <a @click="edit" href="#" class="text-indigo-600 hover:text-indigo-900"
        >Edit</a
      >
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    endpoint: {
      type: Object,
      default: null,
    },
  },
  emits: ["edit"],
  methods: {
    edit() {
      this.$emit("edit", this.endpoint);
    },
  },
};
</script>
