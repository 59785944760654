<template>
  <div class="flex flex-col mx-4">
    <div class="overflow-x-auto">
      <button
        @click="openAddEndpoint(null)"
        type="button"
        class="
          mb-1
          float-right
          relative
          inline-flex
          items-center
          px-3
          py-2
          border border-transparent
          shadow-sm
          text-sm
          rounded-md
          text-white
          bg-gray-700
          hover:bg-gray-800
          focus:outline-none
        "
      >
        Add Device
      </button>

      <div class="py-2 align-middle inline-block min-w-full">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  username
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  callerid
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  notes
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  webrtc
                </th>

                <!-- <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  voicemail_greeting
                </th> -->
                <th scope="col" class="relative px-6 py-3">
                  <ClockIcon
                    v-if="loading"
                    class="animate-spin h-3. w-4 text-gray-700"
                    aria-hidden="true"
                  />
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <EndpointItem
                @edit="openAddEndpoint"
                v-for="endpoint in endpoints"
                :key="endpoint.username"
                :endpoint="endpoint"
              />
            </tbody>
          </table>

          <div v-if="endpoints.length === 0" class="mx-3 mb-2">
            <EmptyTable label="You do not have any endpoint." />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <Pagination />
    </div>
  </div>

  <AddEndpoint
    ref="addEditpoint"
    :open="addEndpointOpened"
    @close="addEndpointOpened = false"
  />
</template>

<script>
import { ClockIcon } from "@heroicons/vue/solid";
import EndpointItem from "@/modules/end_points/views/endpoint_item.vue";
import Pagination from "@/modules/end_points/views/endpoints_pagination.vue";
import AddEndpoint from "@/modules/end_points/views/add_endpoint.vue";
import EmptyTable from "@/widgets/common/EmptyTable.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ClockIcon,
    EndpointItem,
    Pagination,
    EmptyTable,
    AddEndpoint,
  },
  data() {
    return {
      addEndpointOpened: false,
    };
  },
  created() {
    this.listEndpoints();
  },
  methods: {
    openAddEndpoint(data) {
      this.$refs.addEditpoint.reset(data);
      this.addEndpointOpened = true;
    },
    ...mapActions("endpoints", ["listEndpoints"]),
  },
  computed: {
    ...mapGetters("endpoints", ["endpoints", "loading"]),
  },
};
</script>
